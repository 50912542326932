<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @cancel="visible = false"
  >
    <div class="input-text" style="display: flex;align-items: center">
      <div class="flex_1" style="display: flex;flex-direction: column;align-items: flex-start">
        <label><b>审核说明：</b></label>
        <a-textarea
            v-model.trim="authRemark"
            placeholder="请输入不通过的原因"
            style="height:150px;"
        />
        <div style="height:100px;">
          <div style="padding-top:10px;color:#FF9900;">审核不通过常见原因（点击选择，可以多选）：</div>
          <a-tag color="red" v-for="item in reasonList" :key="item" style="cursor: pointer;margin:5px"
                 @click="addReason(item)">
            {{ item }}
          </a-tag>
        </div>
      </div>
    </div>
    <template slot="footer">
      <div class="modal-btn">
        <a-button type="primary" :loading="confirmLoading" @click="handleOk(1)">
          通过
        </a-button>
        <a-button type="danger" :loading="confirmLoading" @click="handleOk(-1)" style="margin-left: 40px;">
          不通过
        </a-button>
        <a-button type="default" :loading="confirmLoading" @click="visible=false" style="margin-left:100px;">
          取消
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: "CircleAuditModal",
  components: {},
  data() {
    return {
      visible: false,
      title: "",
      confirmLoading: false,
      authRemark: "",
      record: {}
    };
  },
  computed: {
    reasonList() {
      return '广告,时政,色情,裸露,辱骂,违法犯罪,欺诈,低俗,版权,泄露隐私（请打码）,其他'.split(/,/)
    },
  },
  methods: {
    showModal(record) {
      this.visible = true;
      this.record = record;
      this.authRemark = "";
      this.title = "审核圈子：" + record.id;
      this.auditList = [];
    },
    addReason(item) {
      this.authRemark = this.authRemark + (this.authRemark ? "，" : "") + item
    },
    handleOk(operate) {
      this.confirmLoading = true;
      if (operate == -1 && !this.authRemark) {
        this.confirmLoading = false;
        this.$message.error("请输入审核不通过的理由");
        return;
      }
      let data = {
        dataId: this.record.id,
        auditType: 1,
        passFlag: operate,
        auditRemark: this.authRemark
      };

      this.$postJson('circle/auditCircle', data)
          .then((res) => {
            this.confirmLoading = false;
            this.visible = false;
            this.$emit("success", operate);
          })
          .catch((error) => {
            this.confirmLoading = false;
          });
    },
  },
};
</script>

<style lang="less" scoped>
.modal-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.list-div {
  max-height: 200px;
  overflow-y: auto;
  padding-top: 10px;

  /deep/ .ant-timeline-item {
    padding-bottom: 10px;
  }

  /deep/ .ant-timeline-item-content {
    min-height: auto;
  }
}
</style>
