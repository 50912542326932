<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.id"
              placeholder="圈子ID"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.keyword"
              placeholder="圈子名称/描述"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.city"
              placeholder="城市"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        <a-button v-hasPermission="'circle:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">
          新增
        </a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="images" slot-scope="text, record">
        <div v-if="text.logo" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(text.logo || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(text.logo || '').split(',')"
          />
        </div>
      </template>
      <template slot="owner" slot-scope="text, record">
        <div>{{ record.creator && record.creator.username }}</div>
      </template>
      <template slot="status" slot-scope="text, record">
        <div title="点击审核" @click.stop="auditCircle(record)" style="cursor: pointer">
          <a-tag :color="record.status | dictName('postStatus', 'color')" style="cursor: pointer">
            <span v-if="record.status==0">去审核</span>
            <span v-else>{{ record.status| dictName("postStatus") }}</span>
          </a-tag>
        </div>
<!--        <a-tag :color="record.status | dictName('validStatus', 'color')">{{-->
<!--            record.status | dictName("validStatus")-->
<!--          }}-->
<!--        </a-tag>-->
      </template>
      <template slot="isTop" slot-scope="text, record">
        <a-tag :color="record.isTop | dictName('isTop', 'color')">{{
            record.isTop | dictName("isTop")
          }}
        </a-tag>
      </template>

      <template slot="operation" slot-scope="text, record">
        <a-dropdown
            v-hasAnyPermission="['circle:edit', 'circle:delete','circle:view' ]"
        >
          <a-menu slot="overlay">
            <a-menu-item key="modify" @click="modify($event, record)"
                         v-hasPermission="'circle:edit'">
              <a-icon type="setting"/>
              修改
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item
                key="delete"
                v-hasPermission="'circle:delete'"
                style="color: red"
                @click="getDelete($event, record)"
            >
              <a-icon type="delete"/>
              删除
            </a-menu-item>
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
    <circle-audit-modal ref="auditModal" @success="editSuccess"></circle-audit-modal>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyCircleModal.vue";
import CircleAuditModal from "./circleAuditModal";

export default {
  name: "CircleList",
  mixins: [ListMixin],
  components: {CircleAuditModal, modifyModal},
  data() {
    return {
      listGetUrl: "circle/listByAdmin",
      listMethod: "postJson",
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      this.dataSource = this.dataSource.map((item) => {
        try {
          item.media = JSON.parse(item.media) || [];
        } catch (error) {
          item.media = [];
        }
        return item;
      });
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order
        },
        {
          title: "头像",
          width: 100,
          align: "center",
          // dataIndex: "avatar",
          scopedSlots: {customRender: "images"},
        },
        {
          title: "圈子名称",
          align: "center",
          width: 150,
          dataIndex: "circleName",
        },
        {
          title: "分类",
          align: "center",
          width: 100,
          dataIndex: "category",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'category' && sortedInfo.order
        },
        {
          title: "城市",
          align: "center",
          width: 80,
          dataIndex: "city",
        },
        {
          title: "状态",
          align: "center",
          width: 80,
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order
        },
        {
          title: "描述",
          width: 350,
          align: "center",
          dataIndex: "circleDesc",
        },
        {
          title: "圈主",
          width: 150,
          align: "center",
          scopedSlots: {customRender: "owner"},
        },
        {
          title: "成员个数",
          align: "center",
          width: 100,
          dataIndex: "memberCount",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'memberCount' && sortedInfo.order
        },
        {
          title: "帖子数量",
          align: "center",
          width: 100,
          dataIndex: "postCount",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'postCount' && sortedInfo.order
        },
        {
          title: "话题数量",
          align: "center",
          width: 100,
          dataIndex: "topicCount",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'topicCount' && sortedInfo.order
        },
        {
          title: "是否置顶",
          align: "center",
          width: 100,
          dataIndex: "isTop",
          scopedSlots: {customRender: "isTop"},
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'isTop' && sortedInfo.order
        },
        {
          title: "建立时间",
          width: 150,
          align: "center",
          dataIndex: "createDate",
          sorter: true,
          sortOrder: sortedInfo.columnKey === 'createDate' && sortedInfo.order
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        // {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    modify(e, record) {
      this.$refs.modifyModal.showModal(record,'edit');
    },
    add(){
      this.$refs.modifyModal.showModal(null,'new');
    },
    auditCircle(record){
      this.$refs.auditModal.showModal(record)
    },
    getDelete(e, record) {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除【" + record.circleName + "】圈子吗？！",
        centered: true,
        onOk() {
          that.$postJson("circle/delete", [record.id]).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
